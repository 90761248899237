<template>
  <div class="mobile-container">
    <mobileStatusBar mode="gray" title="客服" bgColor="#e6f1ff" />
    <div class="page-content">
      <div class="icon img-cover"><img src="../../assets/images/contact-icon.png" /></div>
      <div class="title-item">1V1专属客服为您解答</div>
      <div class="sub-title">扫码添加客服解答</div>
      <div class="content-item">
        <div class="title">
          <div class="bar"></div>
          <div class="text">扫码添加客服</div>
        </div>
        <div class="item-list">
          <div v-if="lxfs.wxCode || lxfs.wxNum" class="item">
            <div class="img img-contain">
              <img v-if="lxfs.wxCode" :src="lxfs.wxCode" />
              <img v-else src="../../assets/images/news-nav-icon-4.png" />
            </div>
            <div class="name">
              <span>微信: {{ lxfs.wxNum }}</span>
            </div>
          </div>
          <div v-if="lxfs.qq" class="item">
            <div class="img img-contain">
              <img src="../../assets/images/qq-icon.png" />
            </div>
            <div class="name">
              <span>QQ: {{ lxfs.qq }}</span>
            </div>
          </div>
          <div v-if="websiteInfo.ContactType1 && websiteInfo.Contact1" class="item">
            <div class="img img-contain">
              <img v-if="websiteInfo.ContactType1 == 1" src="../../assets/images/qq-icon.png" />
              <img v-else-if="websiteInfo.ContactType1 == 3" src="../../assets/images/tg-icon.png" />
              <img v-else-if="websiteInfo.ContactType1 == 2 && websiteInfo.Contact1.code" :src="websiteInfo.Contact1.code" />
              <img v-else src="../../assets/images/news-nav-icon-4.png" />
            </div>
            <div class="name">
              <span v-if="websiteInfo.ContactType1 == 1">QQ: {{ websiteInfo.Contact1.num }}</span>
              <span v-if="websiteInfo.ContactType1 == 2">微信: {{ websiteInfo.Contact1.num }}</span>
              <span v-if="websiteInfo.ContactType1 == 3">Telegram: {{ websiteInfo.Contact1.num }}</span>
            </div>
          </div>
          <div v-if="websiteInfo.ContactType2 && websiteInfo.Contact2" class="item">
            <div class="img img-contain">
              <img v-if="websiteInfo.ContactType2 == 1" src="../../assets/images/qq-icon.png" />
              <img v-else-if="websiteInfo.ContactType2 == 3" src="../../assets/images/tg-icon.png" />
              <img v-else-if="websiteInfo.ContactType2 == 2 && websiteInfo.Contact2.code" :src="websiteInfo.Contact2.code" />
              <img v-else src="../../assets/images/news-nav-icon-4.png" />
            </div>
            <div class="name">
              <span v-if="websiteInfo.ContactType2 == 1">QQ: {{ websiteInfo.Contact2.num }}</span>
              <span v-if="websiteInfo.ContactType2 == 2">微信: {{ websiteInfo.Contact2.num }}</span>
              <span v-if="websiteInfo.ContactType2 == 3">Telegram: {{ websiteInfo.Contact2.num }}</span>
            </div>
          </div>
          <div v-if="websiteInfo.ContactType3 && websiteInfo.Contact3" class="item">
            <div class="img img-contain">
              <img v-if="websiteInfo.ContactType3 == 1" src="../../assets/images/qq-icon.png" />
              <img v-else-if="websiteInfo.ContactType3 == 3" src="../../assets/images/tg-icon.png" />
              <img v-else-if="websiteInfo.ContactType3 == 2 && websiteInfo.Contact3.code" :src="websiteInfo.Contact3.code" />
              <img v-else src="../../assets/images/news-nav-icon-4.png" />
            </div>
            <div class="name">
              <span v-if="websiteInfo.ContactType3 == 1">QQ: {{ websiteInfo.Contact3.num }}</span>
              <span v-if="websiteInfo.ContactType3 == 2">微信: {{ websiteInfo.Contact3.num }}</span>
              <span v-if="websiteInfo.ContactType3 == 3">Telegram: {{ websiteInfo.Contact3.num }}</span>
            </div>
          </div>
          <div v-if="websiteInfo.ContactType4 && websiteInfo.Contact4" class="item">
            <div class="img img-contain">
              <img v-if="websiteInfo.ContactType4 == 1" src="../../assets/images/qq-icon.png" />
              <img v-else-if="websiteInfo.ContactType4 == 3" src="../../assets/images/tg-icon.png" />
              <img v-else-if="websiteInfo.ContactType4 == 2 && websiteInfo.Contact4.code" :src="websiteInfo.Contact4.code" />
              <img v-else src="../../assets/images/news-nav-icon-4.png" />
            </div>
            <div class="name">
              <span v-if="websiteInfo.ContactType4 == 1">QQ: {{ websiteInfo.Contact4.num }}</span>
              <span v-if="websiteInfo.ContactType4 == 2">微信: {{ websiteInfo.Contact4.num }}</span>
              <span v-if="websiteInfo.ContactType4 == 3">Telegram: {{ websiteInfo.Contact4.num }}</span>
            </div>
          </div>
          <div v-if="websiteInfo.Qq" class="item">
            <div class="img img-contain">
              <img src="../../assets/images/qq-icon.png" />
            </div>
            <div class="name">
              <span>QQ: {{ websiteInfo.Qq }}</span>
            </div>
          </div>
          <div v-if="websiteInfo.Wx" class="item">
            <div class="img img-contain">
              <img v-if="websiteInfo.Wx.code" :src="websiteInfo.Wx.code" />
              <img v-else src="../../assets/images/news-nav-icon-4.png" />
            </div>
            <div class="name">
              <span>微信: {{ websiteInfo.Wx.num }}</span>
            </div>
          </div>
          <div class="btn blue">尽量联系网页客服</div>
          <!-- <a :href="websiteInfo.QqQun ? websiteInfo.QqQun : 'javascrpit:void(0);'" class="btn">加入QQ群聊</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "Contact",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      lxfs: { wxCode: '', wxNum: '', qq: '' }
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {
    this.handleGetLxfs()
  },
  methods: {
    async handleGetLxfs() {
      var res = await this.$API.getSet.post({
        'Key': 'lxfs'
      })
      const lxfs = JSON.parse(res.info)
			this.lxfs = {
				qq: lxfs.qq,
				wxCode: lxfs.wxCode,
				wxNum: lxfs.wxNum,
			}
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #e6f1ff;
}
.page-content {
  position: relative;
  padding: 0 .32rem .62rem;
  overflow: hidden;
  .icon {
    width: 3.2rem;
    height: 3.2rem;
    position: absolute;
    top: -.2rem;
    right: 0;
  }
  .title-item {
    margin-top: .52rem;
    font-size: .36rem;
    color: #030304;
  }
  .sub-title {
    margin-top: .08rem;
    font-size: .24rem;
    font-weight: 500;
    color: #242941;
  }
  .content-item {
    margin-top: .4rem;
    position: relative;
    z-index: 1;
    padding: .32rem 0;
    border-radius: .16rem;
    background-color: #fff;
    .title {
      width: 100%;
      height: .52rem;
      position: relative;
      .text {
        position: relative;
        line-height: .52rem;
        text-align: center;
        font-size: .36rem;
        font-weight: bold;
        color: #030304;
      }
      .bar {
        width: 2.48rem;
        height: .24rem;
        position: absolute;
        bottom: .02rem;
        left: 50%;
        transform: translateX(-50%);
        background: linear-gradient( 90deg, rgba(19, 153, 255, .2) 0%, rgba(19, 153, 255, 0) 100%);
      }
    }
    .item-list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: wrap;
      .item {
        width: 50%;
        padding: .3rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .img {
          width: 2rem;
          height: 2rem;
          overflow: hidden;
        }
        .name {
          font-size: .32rem;
          color: #333;
          line-height: .48rem;
        }
      }
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5.02rem;
        height: .72rem;
        box-sizing: border-box;
        border: .02rem solid #1399ff;
        border-radius: .08rem;
        margin-top: .32rem;
        color: #1399ff;
        font-size: .24rem;
        &.blue {
          background-color: #1399ff;
          color: #fff;
        }
      }

    }
  }
}
</style>